import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

const AdminDataContext = createContext(null);

// Cache duration of 1 hour
const CACHE_DURATION = 60 * 60 * 1000;

// Default empty state for SSR
const defaultState = {
  artists: [],
  venues: [],
  shows: [],
  isLoading: false,
  error: null,
  refetch: () => Promise.resolve(),
  lastFetched: null,
};

export function AdminDataProvider({ children }) {
  const [adminData, setAdminData] = useState(null);
  const [lastFetched, setLastFetched] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (force = false) => {
      // Skip fetching during SSR
      if (typeof window === 'undefined') return;

      // Return cached data if within cache duration
      if (
        !force &&
        adminData &&
        lastFetched &&
        Date.now() - lastFetched < CACHE_DURATION
      ) {
        console.log('[AdminContext] Using cached data', {
          age: `${Math.round((Date.now() - lastFetched) / 1000 / 60)} minutes`,
          dataSize: {
            artists: adminData.artists?.length || 0,
            venues: adminData.venues?.length || 0,
            shows: adminData.shows?.length || 0,
          },
        });
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        console.log('[AdminContext] Fetching fresh data', {
          force,
          lastFetched: lastFetched
            ? new Date(lastFetched).toISOString()
            : 'never',
        });

        // Add cache-busting query parameter and headers
        const timestamp = Date.now();
        const response = await fetch(`/fetch-admin-data?t=${timestamp}`, {
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
          cache: 'no-store',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch admin data');
        }
        const { data } = await response.json();
        setAdminData(data);
        setLastFetched(Date.now());

        console.log('[AdminContext] Fresh data fetched', {
          timestamp: new Date().toISOString(),
          dataSize: {
            artists: data.artists?.length || 0,
            venues: data.venues?.length || 0,
            shows: data.shows?.length || 0,
          },
        });
      } catch (err) {
        const errorMsg = 'Error fetching admin data: ' + err.message;
        console.error('[AdminContext] ' + errorMsg);
        setError(errorMsg);
      } finally {
        setIsLoading(false);
      }
    },
    [adminData, lastFetched]
  );

  // Initial fetch on mount - only in browser
  useEffect(() => {
    if (typeof window !== 'undefined') {
      console.log('[AdminContext] Provider mounted');
      fetchData();
    }
  }, [fetchData]);

  // Fetch when cache expires - only in browser
  useEffect(() => {
    if (typeof window === 'undefined' || !lastFetched) return;

    const timeUntilRefetch = CACHE_DURATION - (Date.now() - lastFetched);
    console.log('[AdminContext] Scheduling next refresh in', {
      minutes: Math.round(timeUntilRefetch / 1000 / 60),
    });

    const timer = setTimeout(() => {
      console.log('[AdminContext] Cache expired, refreshing data');
      fetchData(true);
    }, timeUntilRefetch);

    return () => clearTimeout(timer);
  }, [lastFetched, fetchData]);

  // If we're in SSR, return default state
  if (typeof window === 'undefined') {
    return (
      <AdminDataContext.Provider value={defaultState}>
        {children}
      </AdminDataContext.Provider>
    );
  }

  const value = {
    artists: adminData?.artists || [],
    venues: adminData?.venues || [],
    shows: adminData?.shows || [],
    isLoading,
    error,
    refetch: () => {
      console.log('[AdminContext] Manual refetch requested');
      return fetchData(true);
    },
    lastFetched,
  };

  return (
    <AdminDataContext.Provider value={value}>
      {children}
    </AdminDataContext.Provider>
  );
}

AdminDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAdminData() {
  // During SSR, return default state
  if (typeof window === 'undefined') {
    return defaultState;
  }

  const context = useContext(AdminDataContext);
  if (!context) {
    throw new Error('useAdminData must be used within an AdminDataProvider');
  }
  return context;
}
