import React from 'react';

import './src/styles/global.css';
import netlifyIdentity from 'netlify-identity-widget';

import { AdminDataProvider } from './src/contexts/AdminDataContext';

export const onInitialClientRender = () => {
  netlifyIdentity.init();

  setTimeout(function () {
    document.getElementById('___loader').style.display = 'none';
  }, 1);
};

export const onRouteUpdate = () => {
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'none';
  }, 1);
};

export const onRouteUpdateDelayed = () => {
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'flex';
  }, 1);
};

// Wrap the root element with providers
export const wrapRootElement = ({ element }) => {
  // Check if we're in an admin route before rendering the provider
  const isAdminRoute =
    typeof window !== 'undefined' &&
    window.location.pathname.startsWith('/admin');

  // Only wrap with AdminDataProvider if we're in an admin route
  return isAdminRoute ? (
    <AdminDataProvider>{element}</AdminDataProvider>
  ) : (
    element
  );
};
